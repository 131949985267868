import {
  type Filter,
  type CustomChartSerie,
} from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { type ProjectMeta } from "@tokenterminal/tt-analytics-api-types/dist/api/projects"
import { dequal } from "dequal"
import { atom, type Atom } from "jotai"
import { atomFamily } from "jotai/utils"
import {
  getProjectsAtom,
  getProjectsByBlockchainAtom,
  getProjectsByMarketSectorAtom,
  getProjectsByMetricAtom,
} from "../../../../data/store/projects-atom"
import { unwrapWithData } from "../../../../utils/jotai/unwrap"
import { toDictionary } from "../../../../utils/toDictionary"
import { marketSectorsAtom } from "./meta-atoms"
import { getMetricCompositionChartDataByMetricAndChainAtom } from "./metric-composition-data-atom"
import { getAggregatedChartDataByMetricAndBlockchainAtom } from "./metrics-blockchain-data-atom"

function getFiltersAsRecord(
  filters: Array<Filter>
): Record<NonNullable<Filter["type"]>, Filter> {
  return filters.reduce(
    (acc, filter) => {
      if (filter.type) {
        acc[filter.type] = filter
      }

      return acc
    },
    {} as Record<NonNullable<Filter["type"]>, Filter>
  )
}

function getSortedKeysBasedOnValues<T>(
  items: Array<T>,
  getKey: (item: T) => string,
  getValue: (item: T) => number
): Array<string> {
  const groupedData = items.reduce(
    (acc, item) => {
      const key = getKey(item)
      acc[key] = (acc[key] || 0) + getValue(item)
      return acc
    },
    {} as Record<string, number>
  )

  const values = new Map<string, number>()
  // Sorting and formatting results
  Object.entries(groupedData)
    .sort(([, sumA], [, sumB]) => sumB - sumA)
    .forEach(([key, sum]) => {
      values.set(key, sum)
    })

  return Array.from(values.keys())
}

function getChartInfoByMarketSectorsAtom({
  filters,
  groupBy,
  metric,
}: Pick<CustomChartSerie, "filters" | "groupBy" | "metric">): Atom<
  Promise<Array<{ id: string; name: string }>>
> {
  const chartInfoByMarketSectorsAtom = atom(async (get) => {
    const filtersAsRecord = getFiltersAsRecord(filters) as ReturnType<
      typeof getFiltersAsRecord
    > & { market_sector: Filter }
    if (!filtersAsRecord.market_sector) {
      throw new Error("Market sector filter is required")
    }

    // group by chain = Market sector
    if (groupBy === "chain") {
      const marketSectors = unwrapWithData(await get(marketSectorsAtom))

      return marketSectors
        .filter((sector) => {
          return filtersAsRecord.market_sector.values.includes(sector.id)
        })
        .map((marketSector) => {
          return {
            id: marketSector.id,
            name: marketSector.name,
          }
        })
    }

    // Handle unique grouping by projects
    const projectsMap = new Map<
      string,
      {
        id: string
        name: string
      }
    >()

    const projectsByMetric = await get(getProjectsByMetricAtom(metric))
    const projectResults = await Promise.all(
      filtersAsRecord.market_sector.values.map((marketSector) =>
        get(getProjectsByMarketSectorAtom(marketSector))
      )
    )
    projectResults.flat().forEach((project) => {
      if (projectsByMetric.includes(project)) {
        projectsMap.set(project.data_id, {
          id: project.data_id,
          name: project.name,
        })
      }
    })

    return Array.from(projectsMap.values())
  })

  return chartInfoByMarketSectorsAtom
}

function getChartInfoByBlockchainAtom({
  filters,
  groupBy,
  metric,
}: Pick<CustomChartSerie, "filters" | "groupBy" | "metric">): Atom<
  Promise<Array<{ id: string; name: string }>>
> {
  const chartInfoByBlockchainAtom = atom(async (get) => {
    const filtersAsRecord = getFiltersAsRecord(filters) as ReturnType<
      typeof getFiltersAsRecord
    > & { blockchain: Filter }
    if (!filtersAsRecord.blockchain) {
      throw new Error("Blockchain filter is required")
    }

    const projects = unwrapWithData(await get(getProjectsAtom))

    if (groupBy === "chain") {
      // We're querying chart data to get all names of projects and chains
      // TODO have a separate endpoint for this
      const projectAndChainData = unwrapWithData(
        await get(
          getAggregatedChartDataByMetricAndBlockchainAtom({
            // TODO move to metric id
            metric: metric.replace(/-/g, "_"),
            chainIds: filtersAsRecord.blockchain.values,
            projectIds: filtersAsRecord.project?.values ?? [],
            interval: "max",
          })
        )
      )

      const projectDictionary = toDictionary(projects, "data_id")
      const uniqueProjectsMap = new Map<string, { id: string; name: string }>()
      projectAndChainData.map((item) => {
        if (
          item.data_id &&
          item.chain_id &&
          projectDictionary.has(item.data_id)
        ) {
          uniqueProjectsMap.set(`${item.chain_id}`, {
            id: `${item.chain_id}`,
            name: `${projectDictionary.get(item.chain_id)?.name ?? item.chain_id}`,
          })
        }
      })

      return Array.from(uniqueProjectsMap.values())
    } else if (groupBy === "project+chain") {
      let projectsToFetch = filtersAsRecord.project?.values ?? []
      if (filtersAsRecord.market_sector && projectsToFetch.length === 0) {
        const projectResults = await Promise.all(
          filtersAsRecord.market_sector.values.map((marketSector) =>
            get(getProjectsByMarketSectorAtom(marketSector))
          )
        )
        projectsToFetch = projectResults
          .flat()
          .map((project) => project.data_id)
      }

      // We're querying chart data to get all names of projects and chains
      // TODO have a separate endpoint for this
      const projectAndChainData = unwrapWithData(
        await get(
          getAggregatedChartDataByMetricAndBlockchainAtom({
            // TODO move to metric id
            metric: metric.replace(/-/g, "_"),
            chainIds: filtersAsRecord.blockchain.values,
            projectIds: projectsToFetch,
            interval: "max",
          })
        )
      )
      const projectDictionary = toDictionary(projects, "data_id")
      return (
        getSortedKeysBasedOnValues(
          projectAndChainData,
          (item) => `${item.chain_id}-${item.data_id}`,
          (item) => item.value
        )
          // make sure project is known
          .filter((key) => {
            const [chain, project] = key.split("-")

            return (
              projectDictionary.has(project!) && projectDictionary.has(chain!)
            )
          })
          .map((key) => {
            const [chain, project] = key.split("-")

            return {
              id: key,
              name: `${projectDictionary.get(project!)?.name ?? project} (${projectDictionary.get(chain!)?.name ?? chain})`,
            }
          })
      )
    }

    // Handle unique grouping by projects
    const projectsMap = new Map<
      string,
      {
        id: string
        name: string
      }
    >()

    const availableProjectsByMarketSector = new Set<string>()
    const marketSectorFilter = filtersAsRecord.market_sector?.values ?? []
    if (marketSectorFilter.length === 0) {
      projects.forEach((project) => {
        availableProjectsByMarketSector.add(project.data_id)
      })
    } else {
      const projectsByMarketSector = await Promise.all(
        marketSectorFilter.map((sector) =>
          get(getProjectsByMarketSectorAtom(sector))
        )
      )

      projectsByMarketSector.flat().forEach((project) => {
        availableProjectsByMarketSector.add(project.data_id)
      })
    }

    const projectsByMetric = await get(getProjectsByMetricAtom(metric))
    let projectResults: Array<ProjectMeta> = []
    if (filtersAsRecord.project) {
      const projectDictionary = toDictionary(projects, "data_id")
      projectResults = filtersAsRecord.project.values.map(
        (projectId) => projectDictionary.get(projectId)!
      )
    } else {
      projectResults = (
        await Promise.all(
          filtersAsRecord.blockchain.values.map((blockchain) =>
            get(getProjectsByBlockchainAtom(blockchain))
          )
        )
      ).flat()
    }

    projectResults.forEach((project) => {
      if (
        projectsByMetric.includes(project) &&
        availableProjectsByMarketSector.has(project.data_id)
      ) {
        projectsMap.set(project.data_id, {
          id: project.data_id,
          name: project.name,
        })
      }
    })

    return Array.from(projectsMap.values())
  })

  return chartInfoByBlockchainAtom
}

function getChartInfoByProjectsAtom({
  filters,
  groupBy,
  metric,
}: Pick<CustomChartSerie, "filters" | "groupBy" | "metric">): Atom<
  Promise<Array<{ id: string; name: string }>>
> {
  const chartInfoByProjectsAtom = atom(async (get) => {
    const filtersAsRecord = getFiltersAsRecord(filters) as ReturnType<
      typeof getFiltersAsRecord
    > & { project: Filter }
    if (!filtersAsRecord.project) {
      throw new Error("Project filter is required")
    }

    const projects = unwrapWithData(await get(getProjectsAtom))
    const projectDictionary = toDictionary(projects, "data_id")
    const projectsToFetch = filtersAsRecord.project.values

    if (groupBy === "chain") {
      const chainsCompositionPayload = await get(
        getMetricCompositionChartDataByMetricAndChainAtom({
          composeBy: "chain",
          metric: metric,
          projects: projectsToFetch,
          interval: "24h",
        })
      )
      const chainsCompositionData = unwrapWithData(chainsCompositionPayload)

      const projectDictionary = toDictionary(projects, "data_id")
      return (
        getSortedKeysBasedOnValues(
          chainsCompositionData,
          (item) => `${item.composition_value}-${item.data_id}`,
          (item) => item.value
        )
          // make sure project is known
          .filter((key) => {
            const [chain, project] = key.split("-")

            return (
              projectDictionary.has(project!) && projectDictionary.has(chain!)
            )
          })
          .map((key) => {
            const [chain, project] = key.split("-")

            return {
              id: key,
              name: `${projectDictionary.get(project!)?.name ?? project} (${projectDictionary.get(chain!)?.name ?? chain})`,
            }
          })
      )
    }

    const projectsByMetric = await get(getProjectsByMetricAtom(metric))
    const projectsByMetricDictionary = toDictionary(projectsByMetric, "data_id")

    return (
      projectsToFetch
        // make sure project is known
        .filter((projectId) => projectsByMetricDictionary.has(projectId))
        .map((projectId) => {
          return {
            id: projectId,
            name: `${projectDictionary.get(projectId!)?.name ?? projectId}`,
          }
        })
    )
  })

  return chartInfoByProjectsAtom
}

export const getChartInfoAtom = atomFamily((chartSetting: CustomChartSerie) => {
  const chartInfoAtom = atom(async (get) => {
    if (!chartSetting.metric) {
      return [] satisfies Array<string>
    }

    if (!chartSetting.groupBy) {
      return [
        {
          id: "aggregated",
          name: "Aggregated",
        },
      ]
    }

    const filters = chartSetting.filters.reduce(
      (acc, filter) => {
        if (filter.type) {
          acc[filter.type] = filter
        }

        return acc
      },
      {} as Record<NonNullable<Filter["type"]>, Filter>
    )

    if (
      chartSetting.filters.length === 1 &&
      chartSetting.filters[0]!.type === "market_sector"
    ) {
      return get(getChartInfoByMarketSectorsAtom(chartSetting))
    }

    if (filters.blockchain) {
      return get(getChartInfoByBlockchainAtom(chartSetting))
    }

    if (filters.project) {
      return get(getChartInfoByProjectsAtom(chartSetting))
    }

    return []
  })

  return chartInfoAtom
}, dequal)
