import { dequal } from 'dequal'
import {
  type Axis,
  type XAxisOptions,
  type YAxisOptions,
  type Series,
} from 'highcharts/highstock'
import { useContext, useEffect, useRef, useState } from 'react'
import { useIsMobile } from '../../hooks/isMobile'
import { useResizeObserver } from '../../hooks/useResizeObserver'
import { Box } from '../Box'
import { Stack } from '../Stack'
import { type ThemeMode, useThemeContext } from '../Theme/ThemeContext'
import { useXAxisOptions } from './axes/xAxes'
import {
  opacityTransitionCss,
  themeClass,
  chartContainer,
  centerCss,
} from './Chart.css'
import { ChartContext } from './ChartContext'
import { SERIE_STACK } from './ChartOptions'
import { COLORS_LARGE_SET } from './colors'
import EmptyState from './EmptyState'
import NoDataEmptyState from './NoDataEmptyState'
import { type TooltipOptions } from './Tooltip'
import {
  createChartSerie,
  type ChartSerie,
  type ChartSerieTimeData,
  type ChartSerieNonTimeData,
  convertChartDataToAggregatedData,
  useYAxisOptions,
} from './useHighchartOptions'
import { useInitializeHighcharts } from './useInitializeHighcharts'
import { calculateGranularityUnit } from './utils/calculateGranularityUnit'
import { createSerieId } from './utils/createSerieId'
import { fixCumulativeData } from './utils/fixCumulativeData'
import {
  type GranularityType,
  groupDataBasedOnGranularity,
} from './utils/groupDataBasedOnGranularity'
import { hideDataBeforeCurrentPeriod } from './utils/hideDataNotInCurrentPeriod'
import { Watermark } from './Watermark'

export type { ChartSerieData } from './useHighchartOptions'
export type {
  GranularityType,
  ChartMetricApproximation,
} from './utils/groupDataBasedOnGranularity'
export type {
  ChartSerie,
  ChartSerieTimeData,
  ChartSerieNonTimeData,
} from './useHighchartOptions'

export type { YAxisOptions } from 'highcharts'

/** Format could be hex strings or chart color palette color ID (e.g.
 * `$tt-chart-color-1`). Color palette IDs can be parsed using the
 * getColorPaletteColorVariableById or computeVariableByColorId helpers. */
export type ChartSerieColor = string

export type ChartProps<T extends boolean = boolean> = {
  series: Array<Omit<ChartSerie, 'data'>>
  seriesData: T extends boolean
  ? T extends false
  ? Record<string, ChartSerieNonTimeData>
  : Record<string, ChartSerieTimeData>
  : never
  showNavigator?: boolean
  zoom: number
  granularity?: GranularityType
  xAxisOptions?: Partial<XAxisOptions>
  yAxisOptions?: Partial<YAxisOptions> | Array<Partial<YAxisOptions>>
  tooltipOptions?: Partial<TooltipOptions>
  aggregated?: boolean
  isTimeBased?: T
  colors?: Array<ChartSerieColor>
  legend?: boolean
  showCurrentPeriod?: boolean
}

export type ChartPropsTime = Omit<ChartProps<true>, 'isTimeBased'> & {
  isTimeBased?: true
}
export type ChartPropsNonTime = Omit<ChartProps<false>, 'isTimeBased'> & {
  isTimeBased: false
}

export type ChartPropsUnion = ChartPropsTime | ChartPropsNonTime

function getSerieColor(colors: Array<string>, index: number) {
  return colors[index] ?? colors[colors.length - 1]
}

type PropCache = {
  granularity: ChartPropsUnion['granularity']
  aggregated: ChartPropsUnion['aggregated']
  isTimeBased: ChartPropsUnion['isTimeBased']
  seriesColorPalette: ChartPropsUnion['colors']
  series: ChartPropsUnion['series']
  seriesData: ChartPropsUnion['seriesData']
  showCurrentPeriod: ChartPropsUnion['showCurrentPeriod']
  zoom: ChartPropsUnion['zoom']
  theme: ThemeMode | undefined
}

export function Chart({
  series,
  seriesData,
  showNavigator = true,
  zoom,
  granularity = 'day',
  xAxisOptions: extraXAxisOptions,
  yAxisOptions: extraYAxisOptions,
  aggregated = false,
  isTimeBased = true,
  colors: seriesColorPalette = COLORS_LARGE_SET,
  tooltipOptions,
  legend = true,
  showCurrentPeriod = true,
}: ChartPropsUnion): JSX.Element {
  const isResizingRef = useRef(false)
  const resizeCache = useRef({ width: 0, height: 0 })
  const watermarkRef = useRef<SVGSVGElement>(null)
  const chartParentContainerRef = useRef<HTMLDivElement>(null)
  const rafRef = useRef<number | null>(null)
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const currentGranularity = useRef<string>(granularity)
  const cache = useRef<PropCache>({
    series: [],
    seriesData: {},
    granularity,
    aggregated,
    isTimeBased,
    seriesColorPalette,
    showCurrentPeriod,
    zoom,
    theme: undefined,
  })
  const xAxisCache = useRef<Partial<XAxisOptions> | null>(null)
  const [isMounted, setMounted] = useState(false)
  const { setApi, setChartData } = useContext(ChartContext)
  const { theme } = useThemeContext()

  const isMobile = useIsMobile()
  const chartType = isTimeBased ? 'stockChart' : 'chart'

  const xAxisOptions = useXAxisOptions(
    isTimeBased,
    granularity,
    extraXAxisOptions,
  )
  const yAxisOptions = useYAxisOptions(
    series,
    {
      isMobile,
      aggregated,
    },
    extraYAxisOptions,
  )

  const chartInstanceRef = useInitializeHighcharts(chartContainerRef, {
    chartType,
    showNavigator,
    tooltipOptions: tooltipOptions ?? {},
    legend,
  })

  const redrawChartRef = useRef(false)

  const chartInstance = chartInstanceRef.current
  useEffect(
    function onMount() {
      if (chartInstanceRef.current && !isMounted) {
        setMounted(true)
        setApi(chartInstanceRef.current)
      }
    },
    [chartInstance, isMounted, setApi, chartInstanceRef],
  )

  useEffect(
    function updateAxis() {
      const chartInstance = chartInstanceRef.current
      if (!chartInstance || !isMounted) {
        return
      }

      if (!dequal(xAxisCache.current, xAxisOptions)) {
        xAxisCache.current = xAxisOptions
        // @ts-ignore
        chartInstance.xAxis[0].update(xAxisOptions, false)
        redrawChartRef.current = true
      }

      // 10/23/2024 - commenting out as this seems to be causing issues
      //              with removing the first series when there are multiple
      //              causing Error: Highcharts error #18: www.highcharts.com/errors/18/
      // for (const axis of chartInstance.yAxis) {
      //   const existingYAxis = yAxisOptions.find(
      //     (yAxisOption) => axis.userOptions.id === yAxisOption.id,
      //   )

      //   if (!existingYAxis && axis.userOptions.id !== 'navigator-y-axis') {
      //     axis.remove(false)
      //     redrawChartRef.current = true
      //   }
      // }

      for (const yAxisOption of yAxisOptions) {
        const existingYAxis = chartInstance.get(yAxisOption.id!)! as Axis
        if (existingYAxis) {
          existingYAxis.update(
            {
              // force offset to be undefined, helps with proper redrawing
              offset: undefined,
              ...yAxisOption,
            },
            false,
          )
        } else {
          chartInstance.addAxis(yAxisOption, false, false, false)
        }
        redrawChartRef.current = true
      }
    },
    [xAxisOptions, yAxisOptions, isMounted, chartInstanceRef],
  )

  useEffect(
    function AddAndRemoveSeries() {
      const chartInstance = chartInstanceRef.current
      if (!chartInstance || !isMounted) {
        return
      }

      if (
        dequal(cache.current, {
          granularity,
          zoom,
          showCurrentPeriod,
          aggregated,
          isTimeBased,
          seriesColorPalette,
          series,
          seriesData,
          theme,
        })
      ) {
        return
      }

      cache.current = {
        granularity,
        zoom,
        aggregated,
        isTimeBased,
        seriesColorPalette,
        series,
        seriesData,
        showCurrentPeriod,
        theme,
      }
      cache.current.series = JSON.parse(JSON.stringify(series))
      cache.current.seriesData = JSON.parse(JSON.stringify(seriesData))

      let modifiedSeries = [...series]
      let modifiedSeriesData = seriesData
      if (isTimeBased && aggregated && series.length) {
        modifiedSeries = [
          {
            ...series[0],
            label: series[0].yAxis,
            name: series[0].yAxis,
          },
        ]
        modifiedSeriesData = {
          [cache.current.series[0].yAxis]: convertChartDataToAggregatedData(
            Array.from(Object.values(seriesData)) as Array<ChartSerieTimeData>,
          ),
        }
      }

      const newSeries = modifiedSeries.map((serie) => createSerieId(serie.name))
      const currentSeries =
        chartInstance.series?.map((serie) => serie.options.id!) ?? []

      for (const serie of currentSeries) {
        if (!newSeries.includes(serie)) {
          const serieToRemove = chartInstance.get(serie) as Series
          serieToRemove?.remove(false, false)
          redrawChartRef.current = true
        }
      }

      // make sure the charts have the same values
      if (Object.keys(modifiedSeriesData).length && isTimeBased) {
        let serieWithAllZoom: ChartSerieTimeData = []
        Object.values(modifiedSeriesData).forEach((data) => {
          if (serieWithAllZoom.length < data.length) {
            serieWithAllZoom = data
          }
        })

        Object.values(
          modifiedSeriesData as Record<string, ChartSerieTimeData>,
        ).forEach((data) => {
          if (serieWithAllZoom.length !== data.length) {
            const allAvailableTimestampsInCurrentSerie = data.map(
              (row) => row[0],
            )

            serieWithAllZoom.forEach((row) => {
              if (!allAvailableTimestampsInCurrentSerie.includes(row[0])) {
                data.push([row[0], null])
              }
            })
            data.sort((a, b) => a[0] - b[0])
          }
        })
      }

      const modifiedData = modifiedSeries.map((serie, index) => {
        let serieData = modifiedSeriesData[serie.name] ?? []

        if (isTimeBased && granularity !== 'day') {
          // When grouping the xAxis misbehaves sometimes, leading it to show an empty gap at the end
          // @see https://jsfiddle.net/fwxq8yrk/217/
          serieData = groupDataBasedOnGranularity(
            serieData as ChartSerieTimeData,
            {
              approximation: serie.groupingApproximation,
              unit: calculateGranularityUnit(granularity),
            },
            zoom,
          )

          if (!showCurrentPeriod) {
            serieData = hideDataBeforeCurrentPeriod(
              serieData as ChartSerieTimeData,
              granularity,
            )
          }
        }

        // Temporary fix to handle cumulative until https://github.com/highcharts/highcharts/issues/18010 is fixed
        if (serie.cumulative && isTimeBased) {
          serieData = fixCumulativeData(
            serieData as ChartSerieTimeData,
            'day',
          )
        }

        let serieToUpdate = chartInstance!.get(
          createSerieId(serie.name),
        )! as Series

        const groupPadding = 0
        if (serieToUpdate) {
          if (
            currentGranularity.current === granularity &&
            serieToUpdate.points?.length > 0
          ) {
            const previousData = serieToUpdate.points.map((point) => [
              point.x,
              point.y,
            ])

            if (!dequal(serieData, previousData)) {
              // TODO: figure out animation
              serieToUpdate.update(
                {
                  ...createChartSerie(
                    {
                      color: getSerieColor(seriesColorPalette, index),
                      ...serie,
                    },
                    {
                      granularity,
                    },
                  ),
                  data: serieData,
                  groupPadding,
                },
                false,
              )
            } else {
              serieToUpdate.update(
                createChartSerie(
                  { color: getSerieColor(seriesColorPalette, index), ...serie },
                  {
                    granularity,
                  },
                ),
                false,
              )
            }
          } else {
            serieToUpdate.update(
              {
                ...createChartSerie(
                  {
                    color: getSerieColor(seriesColorPalette, index),
                    ...serie,
                  },
                  {
                    granularity,
                  },
                ),
                data: serieData,
                groupPadding,
              },
              false,
            )
            // serieToUpdate.setData(serieData, true, true)
          }
        } else {
          serieToUpdate = chartInstance!.addSeries(
            {
              ...createChartSerie(
                {
                  color: getSerieColor(seriesColorPalette, index),
                  ...serie,
                },
                {
                  granularity,
                },
              ),
              data: serieData,
              groupPadding,
            },
            false,
          )
        }

        const seriesYAxisValues = serieData
          .map((row) => row[1])
          .filter((x): x is number => x !== null)
        const dataMin = Math.min(...seriesYAxisValues)
        const dataMax = Math.max(...seriesYAxisValues)
        //   // if all values are zero (possible for example on token incentives)
        //   // we change the type into line so it is visible on bottom of chart
        if (
          dataMin === 0 &&
          dataMax === 0 &&
          serieToUpdate.options.type !== 'line'
        ) {
          serieToUpdate.update(
            {
              type: 'line',
            },
            false,
          )
        }

        // @ts-ignore it's available on certain types and that's enough for us
        if (serieToUpdate.options.stacking === SERIE_STACK.PERCENTAGE) {
          serieToUpdate.yAxis.update(
            {
              min: dataMin < 0 ? -100 : 0,
              max: 100,
            },
            false,
          )
        }

        redrawChartRef.current = true
        currentGranularity.current = granularity
        return serieData
      })

      setChartData(
        series,
        modifiedData as unknown as
        | Array<ChartSerieNonTimeData>
        | Array<ChartSerieTimeData>,
        isTimeBased,
      )
    },
    [
      granularity,
      zoom,
      aggregated,
      isTimeBased,
      seriesColorPalette,
      series,
      seriesData,
      isMounted,
      chartInstanceRef,
      showCurrentPeriod,
      setChartData,
      theme,
    ],
  )

  useEffect(() => {
    const chartInstance = chartInstanceRef.current
    if (redrawChartRef.current && chartInstance) {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current)
      }

      redrawChartRef.current = false
      rafRef.current = requestAnimationFrame(() => {
        if (chartInstance) {
          chartInstance.yAxis.forEach((axis) => {
            if (
              !axis.series.length &&
              !axis.options.id?.startsWith('navigator-')
            ) {
              axis.remove(false)
            }
          })

          // highcharts want to keep current zoom so we need to reset it
          // when users didn't set it themselves.
          const extremes = chartInstance.xAxis[0].getExtremes()
          if (
            (!extremes.userMin && !extremes.userMax) ||
            extremes.userMin === extremes.dataMin ||
            extremes.userMax === extremes.dataMax
          ) {
            chartInstance.xAxis[0].setExtremes(
              undefined,
              undefined,
              false,
              false,
            )
          }
          chartInstance.redraw(false)
        }

        rafRef.current = null
      })
    }

    return () => {
      if (redrawChartRef.current && rafRef.current) {
        cancelAnimationFrame(rafRef.current)
        rafRef.current = null
      }
    }
  })

  useResizeObserver({
    ref: chartParentContainerRef,
    onResize: () => {
      const chartInstance = chartInstanceRef.current
      if (isResizingRef.current || !chartInstance) {
        return
      }

      isResizingRef.current = true

      requestAnimationFrame(() => {
        const rect = chartParentContainerRef.current?.getBoundingClientRect()

        if (
          resizeCache.current.width !== rect?.width ||
          resizeCache.current.height !== rect?.height
        ) {
          const chartInstance = chartInstanceRef.current
          if (chartInstance && rect) {
            chartInstance.setSize(rect?.width, rect?.height, false)
            resizeCache.current.width = rect.width
            resizeCache.current.height = rect.height
          }

          if (chartInstance && watermarkRef.current) {
            Object.assign(watermarkRef.current.style, {
              width: `${chartInstance?.plotWidth}px`,
              height: `${chartInstance?.plotHeight}px`,
              top: `${chartInstance?.plotHeight / 2 + chartInstance?.plotTop
                }px`,
              left: `${chartInstance?.plotWidth / 2 + chartInstance?.plotLeft
                }px`,
              opacity: 0.4,
            })
          }
          isResizingRef.current = false
        } else {
          isResizingRef.current = false
        }
      })
    },
  })

  const hasSeriesData = Object.keys(seriesData).length > 0
  const hasVisibleSeries = series.some((serie) => serie.visible !== false)

  let emptyState = null
  if (!hasSeriesData) {
    emptyState = <NoDataEmptyState />
  } else if (!hasVisibleSeries) {
    emptyState = (
      <EmptyState
        headingText="No legend items selected"
        bodyText="To see data, select at least one legend item."
        size="small"
      />
    )
  }

  return (
    <Box
      zIndex={1}
      maxWidth="100%"
      height="100%"
      position="relative"
      className={[opacityTransitionCss, themeClass, chartContainer]}
      ref={chartParentContainerRef}
    >
      {emptyState && (
        <Stack justifyContent="center" height="100%">
          {emptyState}
        </Stack>
      )}
      <Box
        ref={chartContainerRef}
        height="100%"
        position="absolute"
        style={{
          right: -5,
          left: -5,
        }}
        top="0"
        bottom="0"
      />
      {hasSeriesData && hasVisibleSeries && (
        <>
          <Watermark
            ref={watermarkRef}
            className={centerCss}
            style={{ opacity: 0 }}
          />
        </>
      )}
    </Box>
  )
}
