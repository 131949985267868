import { type Interval } from "@tokenterminal/tt-analytics-api-types/dist/api/zoom"
import { dequal } from "dequal"
import { atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { fetchAtomWithRefresh } from "../../../../api/store/fetch-atom"

export type BlockchainChartDataArgs = {
  metric: string
  chainIds?: Array<string>
  projectIds?: Array<string>
  interval: Interval
  limit?: number
}

function getChainFetchUrl({
  metric,
  chainIds,
  projectIds,
  interval,
  limit,
}: BlockchainChartDataArgs) {
  const search = new URLSearchParams([
    ["interval", interval],
    ["metric_ids", metric],
    [`aggregation`, "sum"],
  ])

  const dimensions = ["chain_id"]
  if (chainIds?.length) {
    dimensions.push("data_id")
    search.append("chain_ids", Array.from(new Set(chainIds)).join(","))
  }

  if (projectIds?.length) {
    search.append("data_ids", projectIds.join(","))
  }

  if (limit) {
    search.append("limit", String(Math.min(limit, 30)))
  }

  search.append("dimensions", dimensions.join(","))

  return `/v3/internal/charts/compositions?${search}` as "/v3/internal/charts/compositions"
}

export const getAggregatedChartDataByMetricAndBlockchainAtom = atomFamily(
  (args: BlockchainChartDataArgs) => {
    return atom(async (get) => {
      const payload = await get(fetchAtomWithRefresh(getChainFetchUrl(args)))

      if (payload.ok) {
        return {
          ok: {
            data: payload.ok.data.map((row) => {
              return {
                ...row,
              }
            }),
          },
          err: null,
        }
      }

      return payload
    })
  },
  (a, b) => dequal(a, b)
)
