import { type CompositionChartPayload } from "@tokenterminal/tt-analytics-api-types/dist/api/charts"
import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"

import { type Interval } from "@tokenterminal/tt-analytics-api-types/dist/api/zoom"
import { type ChartSerieTimeData } from "@tokenterminal/ui/Chart/useHighchartOptions"
import { dequal } from "dequal"
import { atom, type Getter, type Atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { getMetricsAtom } from "../../../../data/store/metrics-atom"
import { getProjectsByMetricAtom } from "../../../../data/store/projects-atom"
import { isDefined } from "../../../../utils/isDefined"
import { type Result, unwrapWithData } from "../../../../utils/jotai/unwrap"
import { toDictionary } from "../../../../utils/toDictionary"
import { generateId } from "../../utils/generate-id"
import { getMetricApproximation } from "../../utils/get-metric-approximation"
import { getProjectsFromFilters } from "../../utils/get-projects-from-filter"
import { getChartInfoAtom } from "./chart-info-atom"
import { metricsConfigurationsAtom, projectsAtom } from "./meta-atoms"
import { getMetricCompositionChartDataByMetricAndChainAtom } from "./metric-composition-data-atom"
import { getAggregatedChartDataByMetricAndBlockchainAtom } from "./metrics-blockchain-data-atom"
import { getMetricDataFromProjectsAtom } from "./metrics-projects-data-atom"

async function getAggregatedChartDataByMetricAndMarketSectors(
  chartSetting: CustomChartSerie,
  zoom: Interval,
  get: Getter
): Promise<Record<string, ChartSerieTimeData>> {
  if (!chartSetting.filters[0]) {
    return {}
  }

  const metricsConfigurations = unwrapWithData(
    await get(metricsConfigurationsAtom)
  )
  const metricConfig = metricsConfigurations.find(
    (metricConfig) => metricConfig.slug === chartSetting.metric
  )
  const projects = unwrapWithData(await get(projectsAtom))
  const projectsMetaDictionary = toDictionary(projects, "data_id")
  const marketSectorFilter = chartSetting.filters.find(
    (f) => f.type === "market_sector"
  )!
  const metricData = await get(
    getMetricDataFromProjectsAtom({
      metric: chartSetting.metric,
      projectSlugs: projects
        .filter((p) => {
          return p?.flattened_tags.some((tag) =>
            marketSectorFilter.values.includes(tag)
          )
        })
        .map((p) => p.slug)
        .filter(isDefined),
      interval: zoom,
    })
  )

  const marketSectorData: Record<string, Record<number, Array<number>>> = {}
  if (metricData.ok && metricData.ok.data?.length) {
    for (const row of metricData.ok.data) {
      const projectMeta = projectsMetaDictionary.get(row.api_id)

      if (!projectMeta) {
        continue
      }

      marketSectorFilter.values.forEach((marketSector) => {
        if (projectMeta.flattened_tags.includes(marketSector)) {
          const rowKey = generateId(chartSetting.id, marketSector)
          const timestamp = new Date(row.timestamp).getTime()
          marketSectorData[rowKey] =
            marketSectorData[rowKey] ||
            ({} satisfies Record<string, Record<number, number>>)
          marketSectorData[rowKey]![timestamp] =
            marketSectorData[rowKey]![timestamp] || []
          marketSectorData[rowKey]![timestamp]!.push(row.value)
        }
      })
    }
  }

  const seriesData = Object.keys(marketSectorData).reduce(
    (acc, curr) => {
      acc[curr] = Object.entries(marketSectorData[curr]!).map(
        ([timestamp, value]) => {
          const filteredValues = value.filter(isDefined)
          if (!filteredValues.length) {
            return [Number(timestamp), null]
          }
          let dayValue = 0
          const returnValue = filteredValues.reduce((a, b) => a + b, 0)
          if (getMetricApproximation(metricConfig) === "average") {
            dayValue = returnValue / filteredValues.length
          }

          // if we are not grouping by project, we want to return the sum of the values
          // TODO: Fix when https://linear.app/token-terminal/issue/DEV-2217/add-edge-case-logic-of-group-by-to-metric-configuration
          // aggregation_enabled gets added
          if (chartSetting.groupBy !== "project") {
            dayValue = returnValue
          }

          return [Number(timestamp), dayValue]
        }
      )
      return acc
    },
    {} as Record<string, ChartSerieTimeData>
  )

  return seriesData
}

async function getAggregatedChartDataByMetricAndChains(
  chartSetting: CustomChartSerie,
  zoom: Interval,
  get: Getter
): Promise<Record<string, ChartSerieTimeData>> {
  const blockchainFilter = chartSetting.filters.find(
    (filter) => filter?.type === "blockchain"
  )

  if (!blockchainFilter) {
    return {}
  }

  let aggregatedData = await get(
    getAggregatedChartDataByMetricAndBlockchainAtom({
      interval: zoom,
      metric: chartSetting.metric.replace(/-/g, "_"),
      chainIds: blockchainFilter.values,
      projectIds: getProjectsFromFilters(chartSetting.filters),
    })
  )

  const marketSectorFilter = chartSetting.filters.find(
    (filter) => filter.type === "market_sector"
  )
  const projectMeta = await get(projectsAtom)
  const projectsMetaDictionary = toDictionary(
    unwrapWithData(projectMeta),
    "data_id"
  )
  if (
    chartSetting.filters.length === 2 &&
    marketSectorFilter &&
    aggregatedData.ok
  ) {
    // clone date to avoid mutation
    // @ts-ignore - types are bad
    aggregatedData = JSON.parse(
      JSON.stringify(aggregatedData)
    ) as unknown as Result<CompositionChartPayload, null>
    aggregatedData.ok!.data = aggregatedData.ok!.data.filter((row) => {
      const projectMeta = projectsMetaDictionary.get(row.data_id!)
      if (!projectMeta) {
        return false
      }

      return projectMeta?.flattened_tags?.some((tag) =>
        marketSectorFilter.values.includes(tag)
      )
    })
  }

  const seriesData: Record<string, ChartSerieTimeData> = {}
  if (aggregatedData.err) {
    return seriesData
  }

  if (chartSetting.groupBy === "chain") {
    const dailySum = new Map<string, number>()
    for (const row of aggregatedData.ok.data) {
      const rowKey = `${row.timestamp}_${row.chain_id}`
      dailySum.set(rowKey, (dailySum.get(rowKey) ?? 0) + row.value)
    }

    for (const [rowKey, value] of dailySum) {
      const [timestamp, chainId] = rowKey.split("_") as [number, string]
      const serieId = generateId(chartSetting.id, chainId)
      seriesData[serieId] = seriesData[serieId] || []
      seriesData[serieId]!.push([new Date(timestamp).getTime(), value])
    }
  } else {
    if (aggregatedData.ok.data?.length) {
      const valuesMap = new Map<`${string}:${string}`, number>()
      for (const row of aggregatedData.ok.data) {
        if (
          row.data_id &&
          row.chain_id &&
          projectsMetaDictionary.has(row.data_id) &&
          projectsMetaDictionary.has(row.chain_id)
        ) {
          let rowKey = row.data_id
          if (chartSetting.groupBy === "project+chain") {
            rowKey = `${row.chain_id}-${row.data_id}`
          }

          const timestamp = new Date(row.timestamp).getTime()
          valuesMap.set(
            `${timestamp}:${rowKey}`,
            (valuesMap.get(`${timestamp}:${rowKey}`) ?? 0) + row.value
          )
        }
      }

      for (const [rowKey, value] of valuesMap) {
        const [timestamp, key] = rowKey.split(":", 2)
        const keyWithSerieId = generateId(chartSetting.id, key!)
        seriesData[keyWithSerieId] = seriesData[keyWithSerieId!] || []
        seriesData[keyWithSerieId]!.push([Number(timestamp!), value])
      }
    }
  }

  return seriesData
}

async function getMetricCompositionChartDataByMetricAndChain(
  chartSetting: CustomChartSerie,
  zoom: Interval,
  get: Getter
) {
  const composedData = await get(
    getMetricCompositionChartDataByMetricAndChainAtom({
      composeBy: "chain",
      interval: zoom,
      metric: chartSetting.metric,
      projects: getProjectsFromFilters(chartSetting.filters),
    })
  )

  const seriesData: Record<string, ChartSerieTimeData> = {}
  if (composedData.ok && composedData.ok.data?.length) {
    for (const row of composedData.ok.data) {
      const rowKey = generateId(
        chartSetting.id,
        `${row.composition_value}-${row.data_id}`
      )
      seriesData[rowKey] = seriesData[rowKey] || []
      seriesData[rowKey]!.push([new Date(row.timestamp).getTime(), row.value])
    }
  }

  return seriesData
}

async function getMetricsDataFromProjects(
  chartSetting: CustomChartSerie,
  zoom: Interval,
  get: Getter
) {
  const projects = toDictionary(
    unwrapWithData(await get(projectsAtom)),
    "data_id"
  )
  const metricData = await get(
    getMetricDataFromProjectsAtom({
      metric: chartSetting.metric,
      projectSlugs: getProjectsFromFilters(chartSetting.filters)
        .map((projectDataId) => projects.get(projectDataId)?.slug)
        .filter(Boolean) as Array<string>,
      interval: zoom,
    })
  )

  const seriesData: Record<string, ChartSerieTimeData> = {}
  if (metricData.ok && metricData.ok.data?.length) {
    for (const row of metricData.ok.data) {
      const rowKey = generateId(chartSetting.id, row.api_id)
      seriesData[rowKey] = seriesData[rowKey] || []

      seriesData[rowKey].push([new Date(row.timestamp).getTime(), row.value])
    }
  }

  return seriesData
}

async function getDataFromChartSetting(
  { chartSetting, zoom }: { chartSetting: CustomChartSerie; zoom: Interval },
  get: Getter
) {
  const filters = chartSetting.filters

  if (filters.length === 1) {
    if (filters[0]!.type === "market_sector") {
      const marketSectorFilter = filters[0]!
      if (chartSetting.groupBy === "project") {
        const projectsMeta = await get(
          getProjectsByMetricAtom(chartSetting.metric)
        )
        const availableProjects = projectsMeta.filter((project) => {
          return project.flattened_tags.some((tag) =>
            marketSectorFilter.values.includes(tag)
          )
        })

        return getMetricsDataFromProjects(
          {
            ...chartSetting,
            filters: [
              {
                type: "project",
                comparator: "in",
                values: availableProjects.map((project) => project.data_id),
              },
            ],
          },
          zoom,
          get
        )
      } else {
        return getAggregatedChartDataByMetricAndMarketSectors(
          chartSetting,
          zoom,
          get
        )
      }
    }
  }

  const blockchainFilter = chartSetting.filters.find(
    (filter) => filter?.type === "blockchain"
  )
  if (blockchainFilter) {
    if (chartSetting.groupBy === "project") {
      const projects = await get(getChartInfoAtom(chartSetting))
      const projectIds = projects.map((project) => project.id)
      const metricsConfiguration = unwrapWithData(await get(getMetricsAtom))
      const metric = metricsConfiguration.find(
        (metric) => metric.slug === chartSetting.metric
      )!

      const isCompositionMetric =
        !!metric?.studio_allowed_group_bys?.includes("project-chain")

      if (isCompositionMetric) {
        const data = await getAggregatedChartDataByMetricAndChains(
          chartSetting,
          zoom,
          get
        )

        return data
      } else {
        return getMetricsDataFromProjects(
          {
            ...chartSetting,
            filters: [
              {
                type: "project",
                comparator: "in",
                values: projectIds,
              },
            ],
          },
          zoom,
          get
        )
      }
    } else {
      return getAggregatedChartDataByMetricAndChains(chartSetting, zoom, get)
    }
  }

  if (chartSetting.groupBy === "chain") {
    return getMetricCompositionChartDataByMetricAndChain(
      chartSetting,
      zoom,
      get
    )
  }

  return getMetricsDataFromProjects(chartSetting, zoom, get)
}

export const getSeriesDataFromChartSettingsAtom = atomFamily(
  function getSeriesDataFromChartSettingsAtom({
    chartSettingsAtom,
    zoom,
  }: {
    chartSettingsAtom: Atom<Promisable<Array<CustomChartSerie>>>
    zoom: Interval
  }) {
    const seriesDataFromChartSettingsAtom = atom(async (get) => {
      const chartSettings = await get(chartSettingsAtom)

      let seriesData: Record<string, ChartSerieTimeData> = {}
      for (const chartSetting of chartSettings) {
        if (!chartSetting.metric) {
          seriesData[chartSetting.title] = []
          continue
        }
        let currentSeriesData: Record<
          string,
          Array<[number, number | null]>
        > = {}

        currentSeriesData = {
          ...currentSeriesData,
          ...(await getDataFromChartSetting(
            {
              chartSetting,
              zoom,
            },
            get
          )),
        }

        if (chartSetting.groupBy) {
          seriesData = {
            ...seriesData,
            ...currentSeriesData,
          }
        } else {
          const serieId = generateId(chartSetting.id, "aggregated")
          const aggregatedData: Record<number, number | null> = {}
          Object.values(currentSeriesData).forEach((data) => {
            for (const [timestamp, value] of data) {
              if (value !== null) {
                aggregatedData[timestamp] = aggregatedData[timestamp] || 0
                aggregatedData[timestamp]! += value
              }
            }
          })

          seriesData[serieId] = []
          for (const timestamp in aggregatedData) {
            seriesData[serieId].push([
              Number(timestamp),
              aggregatedData[timestamp] as number | null,
            ])
          }
        }
      }

      // sort all data
      for (const key in seriesData) {
        if (seriesData[key]) {
          seriesData[key] = seriesData[key].sort((a, b) => a[0] - b[0])
        }
      }

      return seriesData
    })

    if (process.env.NODE_ENV === "development") {
      seriesDataFromChartSettingsAtom.debugLabel = `seriesDataFromChartSettingsAtom(${chartSettingsAtom.debugLabel ?? ""})`
    }

    return seriesDataFromChartSettingsAtom
  },
  (a, b) => dequal(a, b)
)
