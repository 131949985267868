import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { atom } from "jotai"
import { type Atom } from "jotai/ts3.8/vanilla"
import { atomFamily } from "jotai/utils"
import { getChartInfoAtom } from "./chart-info-atom"

export const getLabelsFromChartSettingAtom = atomFamily(
  (chartSettingAtom: Atom<Promisable<CustomChartSerie>>) => {
    const labelsFromChartSettingAtom = atom(async (get) => {
      const chartSetting = await get(chartSettingAtom)
      const chartInfo = await get(getChartInfoAtom(chartSetting))

      return chartInfo.map((info) => {
        return info.name
      })
    })

    if (process.env.NODE_ENV === "development") {
      labelsFromChartSettingAtom.debugLabel = `labelsFromChartSettingAtom(${chartSettingAtom.debugLabel ?? ""})`
    }

    return labelsFromChartSettingAtom
  }
)
