import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { type MetricConfiguration } from "@tokenterminal/tt-analytics-api-types/dist/api/metrics"
import { type ChartSerie } from "@tokenterminal/ui/Chart/Chart"
import {
  SERIE_TYPE,
  type SERIE_FORMAT,
} from "@tokenterminal/ui/Chart/ChartOptions"
import { atom, type Atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { unwrapWithData } from "../../../../utils/jotai/unwrap"
import { toDictionary } from "../../../../utils/toDictionary"
import { SERIE_TYPES } from "../../types"
import { generateId } from "../../utils/generate-id"
import { getChartInfoAtom } from "./chart-info-atom"
import { metricsConfigurationsAtom } from "./meta-atoms"
import { getYaxisMergePaths } from "./yaxis-options-atom"

function getStack(serieType: `${SERIE_TYPES}`, isPercentageShare: boolean) {
  if (
    serieType !== SERIE_TYPES.BAR_STACKED &&
    serieType !== SERIE_TYPES.AREA_STACKED
  ) {
    return undefined
  }

  return isPercentageShare ? "percent" : "normal"
}

function convertChartTypeToHighcharts(
  chartType: `${SERIE_TYPES}`
): `${SERIE_TYPE}` {
  switch (chartType) {
    case SERIE_TYPES.LINE:
      return SERIE_TYPE.LINE
    case SERIE_TYPES.BAR_STACKED:
    case SERIE_TYPES.BAR_UNSTACKED:
      return SERIE_TYPE.COLUMN
    case SERIE_TYPES.AREA_STACKED:
    case SERIE_TYPES.AREA_UNSTACKED:
      return SERIE_TYPE.AREA
    default:
      return SERIE_TYPE.LINE
  }
}

function getMetricApproximation(
  metric: MetricConfiguration | undefined
): "average" | "sum" {
  if (metric?.static) {
    return "average"
  }
  return "sum"
}

export const getSeriesFromChartSettingsAtom = atomFamily(
  function getSeriesFromChartSettingsAtom(
    chartSettingsAtom: Atom<Promisable<Array<CustomChartSerie>>>
  ) {
    const seriesFromChartSettingsAtom = atom(async (get) => {
      const chartSettings = await get(chartSettingsAtom)
      const metricConfigurations = toDictionary(
        unwrapWithData(await get(metricsConfigurationsAtom)) ?? [],
        "slug"
      )

      const yAxisMap = getYaxisMergePaths(chartSettings, metricConfigurations)

      const series: Array<ChartSerie> = []
      const indexOffset = 0

      for (const chartSetting of chartSettings) {
        if (chartSetting) {
          const metricConfig = metricConfigurations.get(chartSetting.metric)!
          const serieType = chartSetting.chart_type
          const highchartSerieType = convertChartTypeToHighcharts(serieType)
          const isAggregated = !chartSetting.groupBy
          const isCumulative = chartSetting.display === "cumulative"
          const isPercentage = chartSetting.display === "percentage"

          const chartInfo = await get(getChartInfoAtom(chartSetting))

          chartInfo.forEach((info, index) => {
            const serie: ChartSerie = {
              index: index + indexOffset,
              yAxis: yAxisMap.get(chartSetting.id) ?? chartSetting.id,
              name: generateId(chartSetting.id, info.id),
              label: isAggregated
                ? chartSetting.title
                : `${info.name} - ${metricConfig.title}`,
              type: highchartSerieType,
              format: metricConfig.format as SERIE_FORMAT,
              groupingApproximation: getMetricApproximation(metricConfig),
              cumulative: isCumulative,
              stack: getStack(serieType, isPercentage),
              visible:
                chartSetting.visible && Array.isArray(chartSetting.visible)
                  ? chartSetting.visible.includes(
                      generateId(chartSetting.id, info.id)
                    )
                  : true,
            }

            // optionally set color
            if (chartSetting.colors[index]) {
              serie.color = chartSetting.colors[index]
            }

            series.push(serie)
          })
        }
      }
      return series
    })

    return seriesFromChartSettingsAtom
  }
)
