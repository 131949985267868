import { colorsV4 } from "@tokenterminal/ui/theme/themeV4/colorsContract.css"

export { getComputedVariableColor } from "@tokenterminal/ui/Chart/colors"

export const colorPaletteColorsById = {
  "$tt-chart-color-1": colorsV4.chart[1],
  "$tt-chart-color-2": colorsV4.chart[2],
  "$tt-chart-color-3": colorsV4.chart[3],
  "$tt-chart-color-4": colorsV4.chart[4],
  "$tt-chart-color-5": colorsV4.chart[5],
  "$tt-chart-color-6": colorsV4.chart[6],
  "$tt-chart-color-7": colorsV4.chart[7],
  "$tt-chart-color-8": colorsV4.chart[8],
  "$tt-chart-color-9": colorsV4.chart[9],
  "$tt-chart-color-10": colorsV4.chart[10],
  "$tt-chart-color-12": colorsV4.chart[12],
  "$tt-chart-color-13": colorsV4.chart[13],
  "$tt-chart-color-14": colorsV4.chart[14],
  "$tt-chart-color-15": colorsV4.chart[15],
  "$tt-chart-color-16": colorsV4.chart[16],
  "$tt-chart-color-17": colorsV4.chart[17],
  "$tt-chart-color-18": colorsV4.chart[18],
  "$tt-chart-color-19": colorsV4.chart[19],
  "$tt-chart-color-20": colorsV4.chart[20],
  "$tt-chart-color-21": colorsV4.chart[21],
} as const

/** Lookup up of color palette color variables by their ID. The variables are
 * defined within the theme and are light & dark theme aware. */

export type ColorPaletteColorId = keyof typeof colorPaletteColorsById

export const isColorPaletteColorId = (
  id: string | keyof typeof colorPaletteColorsById
): id is keyof typeof colorPaletteColorsById => {
  return id in colorPaletteColorsById
}

/**
 * Returns the color variable for a color palette color by its ID (e.g.
 * `$tt-chart-color-1`). If the ID is not a valid color palette color ID, it
 * returns null. Will automatically change with theme changes.
 */

export const getColorPaletteColorVariableById = (id: string) => {
  if (isColorPaletteColorId(id)) {
    return colorPaletteColorsById[id]
  }

  return undefined
}

const availableColors = Object.keys(colorPaletteColorsById)

export function getAvailableColor(index: number): string {
  if (index >= availableColors.length) {
    return availableColors[availableColors.length - 1]!
  }

  return availableColors[index]!
}
