import { colorsV4 } from '@tokenterminal/ui/theme/themeV4/colorsContract.css'

export const TREND_COLOR_SET = {
  positive: '#1AFFAC',
  negative: '#FF5E1A',
}

/** A list of all color palette colors as variables. */

export const COLORS_LARGE_SET = Object.values(colorsV4.chart)

export const getColors = (length: number) => {
  return COLORS_LARGE_SET.slice(0, length)
}

export const getComputedVariableColor = (color: string) => {
  if (color.startsWith('var(')) {
    return getComputedStyle(document.body).getPropertyValue(
      color.replace('var(', '').replace(')', ''),
    )
  }

  return color
}
