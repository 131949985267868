import { type ProjectMeta } from "@tokenterminal/tt-analytics-api-types/dist/api/projects"
import { atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { fetchAtomWithRefresh } from "../../../../api/store/fetch-atom"
import { getProjectsAtom } from "../../../../data/store/projects-atom"
import { isDefined } from "../../../../utils/isDefined"
import { unwrapWithData } from "../../../../utils/jotai/unwrap"
import { toDictionary } from "../../../../utils/toDictionary"
export { getMetricsAtom as metricsConfigurationsAtom } from "../../../../data/store/metrics-atom"

export const marketSectorsAtom = fetchAtomWithRefresh(
  "/v3/internal/market-sectors"
)

export const projectsAtom = getProjectsAtom

export const chainsAtom = fetchAtomWithRefresh("/v3/internal/chains")

export const getMarketSectorAvailabilityByMetricAtom = atomFamily(
  (metric: string) => {
    const marketSectorAvailabilityAtom = fetchAtomWithRefresh(
      `/v3/internal/metrics/${metric}/market-sector-availability` as `/v3/internal/metrics/:slug/market-sector-availability`
    )

    const marketSectorAvailabilityByMetricAtom = atom(async (get) => {
      const [marketSectorMeta, marketSectorAvailability] = await Promise.all([
        get(marketSectorsAtom),
        get(marketSectorAvailabilityAtom),
      ])

      const marketSectorMetaDictionary = toDictionary(
        unwrapWithData(marketSectorMeta),
        "slug"
      )
      return unwrapWithData(marketSectorAvailability)
        .map((marketSector) => {
          return marketSectorMetaDictionary.get(marketSector.slug)
        })
        .filter(isDefined)
    })

    return marketSectorAvailabilityByMetricAtom
  }
)

export const getBlockchainsByMetricAtom = atomFamily((metric: string) => {
  const getBlockchainsByMetricAtom = fetchAtomWithRefresh(
    `/v3/internal/metrics/${metric}/chain-availability` as "/v3/internal/metrics/:slug/chain-availability"
  )

  return atom(async (get) => {
    const [availableChainsPayload, chainsMeta, projectsMeta] =
      await Promise.all([
        Promise.resolve(get(getBlockchainsByMetricAtom)).then(unwrapWithData),
        Promise.resolve(get(chainsAtom)).then(unwrapWithData),
        Promise.resolve(get(projectsAtom)).then(unwrapWithData),
      ])

    const chainDictionary = toDictionary(chainsMeta, "chain_id")
    const projectDictionary = toDictionary(projectsMeta, "data_id")

    const result: Array<ProjectMeta> = []
    if (availableChainsPayload) {
      for (const chain of availableChainsPayload) {
        if (chainDictionary.has(chain.chain_id)) {
          const chainInfo = chainDictionary.get(chain.chain_id)!
          if (
            chainInfo.project_data_id &&
            projectDictionary.has(chainInfo.project_data_id)
          ) {
            result.push(projectDictionary.get(chainInfo.project_data_id)!)
          }
        }
      }
    }

    return result
  })
})
