import { type CustomChartSerie } from "@tokenterminal/tt-analytics-api-types/dist/api/customChart"
import { type ChartSerieColor } from "@tokenterminal/ui/Chart/Chart"
import { useThemeContext } from "@tokenterminal/ui/Theme/ThemeContext"
import { useMemo } from "react"
import { SERIE_TYPES } from "../../types"
import {
  getColorPaletteColorVariableById,
  getComputedVariableColor,
} from "../../utils/colors"

export function Symbol({
  serieType,
  color,
}: {
  serieType: CustomChartSerie["chart_type"]
  color: ChartSerieColor
}) {
  const { theme } = useThemeContext()
  const parsedColor = useMemo(
    () =>
      getComputedVariableColor(
        getColorPaletteColorVariableById(color) || color
      ),
    // We want to recompute when the theme changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [color, theme]
  )

  switch (serieType) {
    case SERIE_TYPES.LINE: {
      return (
        <svg
          width="10 "
          height="2"
          viewBox="0 0 5 1"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <line
            x1="0"
            y1="0.5"
            x2="5"
            y2="0.5"
            stroke={parsedColor}
            strokeWidth="1"
          />
        </svg>
      )
    }
    case SERIE_TYPES.BAR_UNSTACKED:
    case SERIE_TYPES.BAR_STACKED:
    case SERIE_TYPES.AREA_STACKED:
    case SERIE_TYPES.AREA_UNSTACKED:
    default: {
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <rect
            x="0"
            y="0"
            width="12"
            height="12"
            fill={parsedColor}
            rx="1"
            ry="1"
          ></rect>
        </svg>
      )
    }
  }
}
