import './theme.css'
// TODO: Rip this out and move it to tt-core
import './themeV4/colorsTheme.css'
export { makeHsla } from './themeV4/utils'

export * from './contracts/themes.css'
export { theme as themeV3 } from './contracts/theme-v3.css'
// NOTE: as safety pre-caution, don't export getThemeColor. It has its own export path in package.json.

export const makeThemedVariables = (
  styles: Array<{
    selector: string
    light: Record<string, string>
    dark: Record<string, string>
  }>,
): any => {
  return {
    selectors: styles.reduce((acc: any, { selector, dark, light }) => {
      return {
        ...acc,
        [`body.theme-dark ${selector}`]: {
          vars: dark,
        },
        [`body.theme-light ${selector}`]: {
          vars: light,
        },
      }
    }, {}),
  }
}
