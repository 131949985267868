import numeral from 'numeral'
import { formatTime } from '../../utils/date'
import { canUseNumeral } from '../../utils/numerals'
import { SERIE_FORMAT } from './ChartOptions'
import type { Point } from 'highcharts'

type Value = string | number | undefined | null

export type FormatterOptions = {
  token?: string
  postfix?: string
  isPercentageShare?: boolean // Required for non % share -series using format: "percent"
}

export type Formatter<T = Point> = (
  formatterOptions: FormatterOptions,
) => (this: T) => string

export function pricingFormatter(value: Value, format = '$0.0a') {
  if (!value) {
    return `0`
  }
  if (canUseNumeral(value)) {
    return numeral(value).format(format)
  }
  return `$${value}`
}

export function percentageFormatter(value: Value) {
  return numeral(value).format('0,0[.]0') + '%'
}

export function ratioFormatter(value: Value) {
  return numeral(value).format('0.0') + 'x'
}

export function numberFormatter(value: Value, format = '0[.]0a') {
  return numeral(value).format(format)
}

export function bytesFormatter(value: Value) {
  return numeral(value).format('0[.]0b')
}

export function timeFormatter(value: Value) {
  if (typeof value === 'undefined' || value === null) {
    return '-'
  }
  if (typeof value === 'string') {
    // Should never be :thinking:
    return formatTime(Number.parseInt(value))
  }
  return formatTime(value)
}

export const thousandsFormatter = (value: Value) => {
  if (!value) {
    return ''
  }

  // times value by 1000
  const times1000 = Number(value) / 1000

  return numeral(times1000).format('$0,0') + ' K'
}

export const formatters = {
  [SERIE_FORMAT.PRICE]: pricingFormatter,
  [SERIE_FORMAT.RATIO]: ratioFormatter,
  [SERIE_FORMAT.PERCENTAGE]: percentageFormatter,
  [SERIE_FORMAT.NUMBER]: numberFormatter,
  [SERIE_FORMAT.TOKEN]: numberFormatter,
  [SERIE_FORMAT.TIME]: timeFormatter,
  [SERIE_FORMAT.BYTES]: bytesFormatter,
  [SERIE_FORMAT.TOKEN_ICP]: numberFormatter,
  [SERIE_FORMAT.TOKEN_CKBTC]: numberFormatter,
  [SERIE_FORMAT.CYCLES_PER_SECONDS]: numberFormatter,
} as const
