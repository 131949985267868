import numeral from 'numeral'
import { canUseNumeral } from '../../utils/numerals'
import { SERIE_FORMAT } from './ChartOptions'
import * as sharedFormatters from './sharedFormatter'
import { type FormatterOptions, type Formatter } from './sharedFormatter'
import type {
  AxisLabelsFormatterCallbackFunction,
  AxisLabelsFormatterContextObject,
} from 'highcharts'

const pricingFormatter: Formatter<AxisLabelsFormatterContextObject> =
  function pricingFormatter() {
    return function () {
      const value = Number(this.value)
      if (value === 0) {
        return '$0'
      }

      if (canUseNumeral(value) && value < 10) {
        if (value < 0.0001) {
          return `$${numeral(this.value).format('0.00e+0')}`
        } else {
          return numeral(this.value).format('$0[.]0[000]a')
        }
      }

      return sharedFormatters.pricingFormatter(this.value, '$0[.]0a')
    }
  }

const percentageFormatter: Formatter<AxisLabelsFormatterContextObject> =
  function percentageFormatter({ isPercentageShare }: FormatterOptions) {
    return function () {
      let value = Number(this.value)
      if (!isPercentageShare) {
        value = value * 100
      }

      return sharedFormatters.percentageFormatter(value)
    }
  }

const ratioFormatter: Formatter<AxisLabelsFormatterContextObject> =
  function ratioFormatter() {
    return function () {
      return sharedFormatters.ratioFormatter(this.value)
    }
  }

const numberFormatter: Formatter<AxisLabelsFormatterContextObject> =
  function numberFormatter() {
    return function () {
      return sharedFormatters.numberFormatter(this.value)
    }
  }

const timeFormatter: Formatter<AxisLabelsFormatterContextObject> =
  function timeFormatter() {
    return function () {
      return sharedFormatters.timeFormatter(this.value)
    }
  }

const bytesFormatter: Formatter<AxisLabelsFormatterContextObject> =
  function bytesFormatter() {
    return function () {
      return sharedFormatters.bytesFormatter(this.value)
    }
  }

const formatters = {
  [SERIE_FORMAT.PRICE]: pricingFormatter,
  [SERIE_FORMAT.RATIO]: ratioFormatter,
  [SERIE_FORMAT.PERCENTAGE]: percentageFormatter,
  [SERIE_FORMAT.NUMBER]: numberFormatter,
  [SERIE_FORMAT.TOKEN]: numberFormatter,
  [SERIE_FORMAT.TIME]: timeFormatter,
  [SERIE_FORMAT.BYTES]: bytesFormatter,
  [SERIE_FORMAT.TOKEN_ICP]: numberFormatter,
  [SERIE_FORMAT.TOKEN_CKBTC]: numberFormatter,
  [SERIE_FORMAT.CYCLES_PER_SECONDS]: numberFormatter,
} as const

export function getFormatter(
  serie: `${SERIE_FORMAT}`,
  options: FormatterOptions = {},
): AxisLabelsFormatterCallbackFunction {
  return formatters[serie](options)
}
