import { dequal } from 'dequal'
import { useRef } from 'react'
import { themeV3 } from '../../theme'
import { createYAxis } from './axes/yAxes'
import { type ChartMetricApproximation, type GranularityType } from './Chart'
import { chartCSSVars } from './ChartContract.css'
import { SERIE_FORMAT, SERIE_STACK, type SERIE_TYPE } from './ChartOptions'
import { getComputedVariableColor } from './colors'
import { getFormatter as getDataLabelsFormatter } from './dataLabelsFormatter'
import { getFormatter as getTooltipFormatter } from './tooltipFormatters'
import { createSerieId } from './utils/createSerieId'
import type {
  SeriesAreaOptions,
  SeriesColumnOptions,
  SeriesLineOptions,
  YAxisOptions,
} from 'highcharts'

export type ChartSerieNonTimeData = Array<[string, number]>
export type ChartSerieTimeData = Array<[number, number | null]>
export type ChartSerieData = ChartSerieTimeData | ChartSerieNonTimeData

export interface ChartSerie {
  yAxis: string
  name: string
  label: string
  type: 'line' | 'area' | 'column'
  format: `${SERIE_FORMAT}`
  opposite?: boolean
  index?: number
  legendIndex?: number
  color?: string
  logarithmic?: boolean
  stack?: `${SERIE_STACK}`
  reverse?: boolean
  cumulative?: boolean
  tooltipOptions?: {
    token?: string
  }
  groupingApproximation?: ChartMetricApproximation
  visible?: boolean
}

export const convertChartDataToAggregatedData = (
  data: Array<ChartSerieTimeData>,
) => {
  const aggregatedValues = new Map<number, number>()
  data.forEach((serie) => {
    serie.forEach(([timestamp, value]) => {
      const current = aggregatedValues.get(timestamp) ?? 0
      aggregatedValues.set(timestamp, current + (value ?? 0))
    })
  })

  return Array.from(aggregatedValues.entries()).sort((a, b) => a[0] - b[0])
}

export function useYAxisOptions(
  series: Array<ChartSerie>,
  {
    isMobile = false,
    aggregated = false,
  }: { isMobile: boolean; aggregated: boolean },
  yAxisOptions?: Array<YAxisOptions> | YAxisOptions,
) {
  const cachedAxes = useRef<Array<YAxisOptions>>([])
  const cache = useRef<{
    series: Array<ChartSerie>
    isMobile: boolean
    aggregated: boolean
    yAxisOptions?: Array<YAxisOptions> | YAxisOptions
  }>({
    series: [],
    isMobile: false,
    aggregated: false,
    yAxisOptions: [],
  })

  if (!dequal(cache.current, { series, isMobile, aggregated, yAxisOptions })) {
    cache.current = { series, isMobile, aggregated, yAxisOptions }

    const yAxis: Array<YAxisOptions> = []
    const seenYAxis = new Set<string>()
    let axisIndex = 0

    series.forEach((serie) => {
      const yAxisName = serie.yAxis
      const isPercentageStack = serie.stack === SERIE_STACK.PERCENTAGE // In UI "% share"

      if (!seenYAxis.has(yAxisName)) {
        let opposite = axisIndex % 2 === 1 && !isMobile
        if (typeof serie.opposite !== 'undefined') {
          opposite = serie.opposite
        }

        const normalizedAxisOptions =
          (Array.isArray(yAxisOptions)
            ? yAxisOptions[axisIndex]
            : yAxisOptions) ?? {}
        const axis: YAxisOptions = createYAxis(serie.yAxis, {
          label: aggregated ? '' : serie.yAxis,
          index: axisIndex,
          format: serie.format,
          opposite,
          isPercentageStack,
          isLogarithmic: serie.logarithmic || false,
          isReversed: serie.cumulative || serie.reverse || false,
          options: normalizedAxisOptions,
        })

        seenYAxis.add(yAxisName)
        yAxis.push(axis)
        axisIndex++
      }
    })

    cachedAxes.current = yAxis
  }

  return cachedAxes.current
}

export function createChartSerie<T extends `${SERIE_TYPE}`>(
  serie: Omit<ChartSerie, 'color'> & { color: string },
  { granularity }: { granularity: GranularityType },
): T extends 'line'
  ? SeriesLineOptions
  : T extends 'area'
  ? SeriesAreaOptions
  : SeriesColumnOptions {
  const isPercentageStack = serie.stack === SERIE_STACK.PERCENTAGE
  const isTimeBased = true

  const highChartSerie:
    | SeriesLineOptions
    | SeriesAreaOptions
    | SeriesColumnOptions = {
    type: serie.type,
    name: serie.label,
    visible: serie.visible,
    id: createSerieId(serie.name),
    color: getComputedVariableColor(serie.color),
    threshold: 0,
    tooltip: {
      pointFormatter: getTooltipFormatter(
        isPercentageStack ? SERIE_FORMAT.PERCENTAGE : serie.format,
        {
          // Temporary fix to handle cumulative until https://github.com/highcharts/highcharts/issues/18010 is fixed
          // cumulative: serie.cumulative,
          ...serie.tooltipOptions,
          isPercentageShare: isPercentageStack,
        },
      ),
    },
    yAxis: serie.yAxis,
    stacking: serie.stack,
    legendIndex: serie.legendIndex,
    index: serie.index,
    // forcing daily granularity as we calculate granularity ourselves
    dataGrouping: {
      enabled: false,
    },
    // we use this in the tooltip formatter for quarter
    custom: {
      granularity,
    },
  }

  if (serie.type === 'column' && !serie.stack) {
    highChartSerie.states = {
      hover: {
        brightness: -0.15,
      },
    }
  }

  if (!isTimeBased) {
    ; (highChartSerie as SeriesColumnOptions).dataLabels = {
      enabled: true,
      color: chartCSSVars.dataLabels.color,
      formatter: getDataLabelsFormatter(serie.format, serie.tooltipOptions),
      style: {
        fontSize: '10px',
        fontFamily: themeV3.fonts.primary,
        textOutline: 'transparent',
        fontWeight: '500',
      },
    }
  }

  return highChartSerie as T extends 'line'
    ? SeriesLineOptions
    : T extends 'area'
    ? SeriesAreaOptions
    : SeriesColumnOptions
}
